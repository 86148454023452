import React, { Component } from 'react';

import Slider from 'react-slick'; // Asegúrate de importar Slider
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './Epicerie.css'; // Asegúrate de que el archivo CSS esté correctamente vinculado

import takis from './takis.webp';
import achiote from './achiote.webp';
import chipotles from './chipotles.webp';
import habanero from './habanero.webp';
import hotnut from './hotnut.webp';
import maseca from './maseca.webp';
import matcha from './matcha.webp';
import nacho from './nacho.webp';
import takisfuego from './takisfuego.webp';
import tortillas from './tortillas.webp';
import valentina from './valentina.webp';
import valentin from './valentin.webp';
import chiles from './chiles.webp';
import habaneroverde from './habaneroverde.webp';
import harimasa from './harimasa.webp';
import fifi from './fifi.webp';
import tortillasazules from './tortillasazules.webp';
import totopos from './totopos.webp';



import slide1 from './smaseca.webp';
import slide2 from './sachiote.webp';
import slide3 from './stakis.webp';
import miImagenDeBorde from  '../Home/border.webp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPepperHot } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import ScrollToTopButton from '../lacarte/ScrollToTopButton'; 


function Epicerie() {
  
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Número de slides a mostrar en cada vista
    slidesToScroll: 1, // Número de slides a desplazarse al cambiar
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 768, // Punto de quiebre para dispositivos móviles (puedes ajustarlo según tus necesidades)
        settings: {
          slidesToShow: 1, // Ajusta el número de slides a mostrar en dispositivos móviles
          slidesToScroll: 1, // Ajusta el número de slides a desplazarse en dispositivos móviles
        }
      }
    ]
  };
 
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="la-carte-page">
      {/* Imagen principal debajo del header */}
      <div className="slider-container">
  
      <Slider {...settings}>
  {/* Slide 1 */}
  <div className="slide">
    <img src={slide1} alt="Slide 1" />
    <div className="slide-title-container">
      <div className="slide-title">Épicerie </div>
    </div>
  </div>
  {/* Slide 2 */}
  <div className="slide">
    <img src={slide2} alt="Slide 2"/>
    <div className="slide-title-container">
      <div className="slide-title">Épicerie </div>
    </div>
  </div>
  {/* Slide 3 */}
  <div className="slide">
    <img src={slide3} alt="Slide 3"/>
    <div className="slide-title-container">
      <div className="slide-title">Épicerie </div>
    </div>
  </div>
</Slider>
</div>
<div className="fixed-image-container">
        <img src={fifi} alt="Burrito Entree" />
        <div className="slide-title-container">
          <div className="slide-title">Epicerie</div>
        </div>
      </div>
      
      <div className='backo'>
      <div className='backi'>
      {/* Barra de pestañas */}
      <div className="tabs-bar">
        <button onClick={() => scrollToSection('Sauces')}>Sauces</button>
        <button onClick={() => scrollToSection('Piments')}>Piments</button>
        <button onClick={() => scrollToSection('Snacks')}>Snacks</button>
        <button onClick={() => scrollToSection('Tortillas')}>Tortillas</button>
      </div>
      
      {/* Secciones de contenido */}
      <div className='listemenu'>
      
        <div className='section-container' id="Sauces">
      
        <div><h1 className='tjaune'>Sauces</h1></div>
         <div className='ordresection'>
        <div className="sectio">
          <img src={valentina} alt="Slide 1"  width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Salsa Valentina</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>La sauce Valentina est un incontournable sur les tables mexicaines. Savant mélange de piments, de vinaigre et d’épices. 370ml</p></div>
          </div>
        </div>
        
          <div className="sectio">
          <img src={matcha} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Salsa Macha</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>La sauce Macha est un incontournable sur les tables mexicaines. Savant mélange de piments, de vinaigre et d’épices. 370ml</p></div>
          </div>
        </div>
        
       
          <div className="sectio">
          <img src={habanero} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Salsa habanera</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={habaneroverde} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Salsa habanera verte</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
        </div>
        
        </div>
        
        <div className='section-container' id="Piments">
        <div><h1 className='tjaune'>Piments</h1></div>
         <div className='ordresection'>
        
    
         
         <div className="sectio">
          <img src={nacho} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Nachos</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Piments</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={chipotles} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Chipotle</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Piments</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={chiles} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Piments</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Piments</p></div>
          </div>
        </div>
        
     
        </div>
        
        </div>



        <div className='section-container' id="Snacks">
        <div><h1 className='tjaune'>Snacks</h1></div>
         <div className='ordresection'>
         
         <div className="sectio">
          <img src={takisfuego} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Takis (Fuego)</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Snack à base de tortilla de mais (pimenté)</p></div>
          </div>
        </div>
        
        <div className="sectio">
          <img src={hotnut} alt="Slide 1"width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Hotnuts</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Snack à base de tortilla de mais (pimenté)</p></div>
          </div>
        </div>
       
        </div>
        
        </div>
        <div className='section-container' id="Tortillas">
        <div><h1 className='tjaune'>Tortillas</h1></div>
         <div className='ordresection'>
         <div className="sectio">
          <img src={tortillas} alt="Slide 1"  width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Tortillas</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p> 500 G - 20 pc</p></div>
          </div>
        </div>
         <div className="sectio">
          <img src={harimasa} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Harimasa</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Harina de Maíz Hari Masa 1kg</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={maseca} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Harina</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Harina de Maíz Hari Masa 1kg</p></div>
          </div>
        </div>


        <div className="sectio">
          <img src={tortillasazules} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Tortillas azules</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Tortillas azules</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={achiote} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Achiote</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={totopos} alt="Slide 1" width="400"  height="300" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Totopos</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
       
        
        </div>
        
      </div>


      
      
      

      </div>
      </div>
      </div>
      <ScrollToTopButton />
    </div>
  );
}

export default Epicerie;