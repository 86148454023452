import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Authentification from './components/Authentification/Authentification';
import Dashboard from './components/dashboard/dashboard';
import './App.css';
import LaCarte from './components/lacarte/lacarte';
import Apropos from './components/Apropos/Apropos';
import Livraison from './components/Livraison/Livraison';
import Epicerie from './components/Epicerie/Epicerie';
import Click from './components/click/click';
import Alaune from './components/Alaune/Alaune';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Authentification" element={<Authentification />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/LaCarte" element={<LaCarte />} />
            <Route path="/Apropos" element={<Apropos />} />
            <Route path="/Livraison" element={<Livraison />} />
            <Route path="/Epicerie" element={<Epicerie />} />
            <Route path='/Click' element={<Click />} />
            <Route path='/Alaune' element={<Alaune />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;