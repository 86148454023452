import React, { useState } from 'react';
import './Apropos.css'; // Asegúrate de crear este archivo CSS en la misma carpeta que tu componente
import localImage from './local.webp';
import axios from 'axios';



function Apropos() {
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [objet, setObjet] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [messageEnvoye, setMessageEnvoye] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('${process.env.REACT_APP_API_URL}/send', {
        nombre,
        email,
        objet,
        mensaje
      });
      setMessageEnvoye(response.data);
    } catch (error) {
      console.error("Erreur lors de l'envoi du message", error);
      setMessageEnvoye('Erreur lors de l\'envoi du message.');
    }
  };

  return (
    <div className="contenedor-contacto">
      <header className="slide-title">
        <h1>Contactez-nous</h1>
      </header>
      <div className='PG'>
        <section className="formulario-contacto">
          <h2>Laissez votre message</h2>
          <form onSubmit={handleSubmit}>
            <div className="campo-form">
              <label htmlFor="nombre">Nom/Prénom</label>
              <input
                type="text"
                id="nombre"
                required
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                placeholder="Nom/Prénom"
              />
            </div>
            <div className="campo-form">
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                id="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail"
              />
            </div>
            <div className="campo-form">
              <label htmlFor="objet">Objet</label>
              <input
                type="text"
                id="objet"
                required
                value={objet}
                onChange={(e) => setObjet(e.target.value)}
                placeholder="Objet"
              />
            </div>
            <div className="campo-form">
              <label htmlFor="mensaje">Votre message ici...</label>
              <textarea
                id="mensaje"
                required
                value={mensaje}
                onChange={(e) => setMensaje(e.target.value)}
                placeholder="Votre message ici..."
                style={{height: '100px', width: '100%'}} // Ajustar según el necesidad
              ></textarea>
            </div>
            <button type="submit">Envoyer</button>
            {messageEnvoye && <p>{messageEnvoye}</p>}
          </form>
        </section>
        <aside className="informacion-contacto">
          <div className='pc'>
            <h2>TACO MEMO - Gambetta</h2>
            <p>209 Rue Léon Gambetta, Lille</p>
            <p>Téléphone: 03 62 23 11 98</p>
          </div>
          <img src={localImage} alt="Imagen del local" width='400px' height='auto' />
        </aside>
      </div>
    </div>
  );
}

export default Apropos;