import React, { Component, useState, Suspense } from 'react';

import './Alaune.css'; // Asegúrate de crear este archivo CSS en la misma carpeta que tu componente
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Importar el componente LazyLoad para la carga diferida
import LazyLoad from 'react-lazyload';

// Mantén tus importaciones de imágenes
import brunch from "./brunchmai.webp";
import menuepicerie from "./menuepicerie.webp";
import brunchjuin from "./brunchjuin.webp";
import cumbias from "./cumbias.webp";
import mariachi from "./mariachi.webp";


function Alaune() {
  
  return (
    <div className="contenedor-contacto">
      <div className="slide-title">A la une</div>
      <div className="informacion-contacto">
        <div className='pc'>
          <h2>Décrouvrez nos dernières nouveautés!</h2>
        </div>
        <div>
          <div className='avisos'>
            {/* Usa LazyLoad para la carga diferida de imágenes */}
            <LazyLoad height={200} offset={100} once>
              <img src={brunchjuin} className="responsive-image" alt="Découvrez notre nouveau brunch de mai" />
            </LazyLoad>
            <LazyLoad height={200} offset={100} once>
              <img src={mariachi} className="responsive-image" alt="Découvrez notre nouveau brunch de mai" />
            </LazyLoad>
            <LazyLoad height={200} offset={100} once>
              <img src={brunch} className="responsive-image" alt="Découvrez notre nouveau brunch de mai" />
            </LazyLoad>
            <LazyLoad height={200} offset={100} once>
              <img src={cumbias} className="responsive-image" alt="Découvrez notre nouveau brunch de mai" />
            </LazyLoad>
            <LazyLoad height={200} offset={100} once>
              <img src={menuepicerie} className="responsive-image" alt="Découvrez notre nouveau menu d'épicerie" />
            </LazyLoad>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Alaune;