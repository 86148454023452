import React, { Component, useState } from 'react';
import './Livraison.css'; // Asegúrate de crear este archivo CSS en la misma carpeta que tu componente
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import deliverooLogo from './deliveroo-logo.webp'; 
import uberEatsLogo from './uber.webp'; 


function Livraison() {
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [objet, setObjet] = useState('');
  const [mensaje, setMensaje] = useState('');
  

  return (
    <div className="contenedor-contacto">
    <div className="slide-title">Livraison</div>
    
    <div className="informacion-contacto">
        <div className='pc'>
        <h2>Retrouvez aussi TACO MEMO chez vous, avec le service de livraison Deliveroo !</h2>
        </div>
        <div>
          <div>
        <a href="https://deliveroo.fr/fr/marques/taco-memo/?utm_medium=backlink&utm_source=Taco-memo%20&utm_campaign=webreferral" target="_blank" rel="noopener noreferrer">
          <img src={deliverooLogo} alt="Ordena en Deliveroo" className="logo" /> 
         
        </a>
        <a href="https://www.ubereats.com/store/taco-memo/NInFou6UWb2sCrMZnhCxTQ?diningMode=DELIVERY" target="_blank" rel="noopener noreferrer">
  <img src={uberEatsLogo} alt="Ordena en Uber Eats" className="logo-delivery" />
</a>
        </div>
       
         
       
         
        
        
        </div>
        
      </div>
      </div>
    
  );
}

export default Livraison;