import React, { Component, useState, useEffect } from 'react';
import './Header.css';
import logo from '../../images/Logotacomemo.webp';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize(); // Verificar inicialmente si es móvil

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!isMobile) {
        if (window.scrollY > 150 || isMenuOpen) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
  
      const textColor = isMobile ? 'white' : isScrolled || isMenuOpen ? 'white' : 'black';
      document.documentElement.style.setProperty('--text-color', textColor);
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile, isMenuOpen, isScrolled]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className={`header-container ${isScrolled ? 'scrolled' : ''}`}>
      <div className='HL'>
        <Link to="/">
          <img src={logo} alt="Logo DE TACO MEMO" className="logo" width="300" height="auto"/>
        </Link>
      </div>
      <div className='ss'>
        <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
          <span className={`menu-text ${isMenuOpen ? 'light-text' : ''}`}>
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </span>
        </div>
        <nav className={`NL ${isMenuOpen ? 'open' : ''}`}>
          <ul className="nav-list">
            <li className={`nav-item ${isMenuOpen ? 'open' : ''}`}onClick={handleLinkClick}><Link to="/LaCarte">LA CARTE</Link></li>
            <li className={`nav-item ${isMenuOpen ? 'open' : ''}`}onClick={handleLinkClick}><Link to="/Epicerie">ÉPICERIE</Link></li>
            <li className={`nav-item ${isMenuOpen ? 'open' : ''}`}onClick={handleLinkClick}><Link to="/APROPOS">CONTACT</Link></li>
            <li className={`nav-item ${isMenuOpen ? 'open' : ''}`}onClick={handleLinkClick}><Link to="/Alaune">A LA UNE</Link></li>
            <li className={`nav-item ${isMenuOpen ? 'open' : ''}`}onClick={handleLinkClick}><Link to="/Livraison">LIVRAISON</Link></li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;