import React, { Component, useState, useEffect } from 'react';
import axios from 'axios'; 
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';
import './Home.css'; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import miImagenDeBorde from './border.webp'; 
import facebookIcon from '../Footer/facebook.webp';
import instagramIcon from '../Footer/instagram.webp';
import deliverooIcon from '../Footer/deliveroo.webp';
import tripadvisorIcon from '../Footer/tripadvisor.webp';
import google from './g.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import etoile from './etoile.webp';
import heart from './heart.webp';
import piment from './piment.webp';
import mobileImage from './mobile.webp';
import image1 from './uploads/1716068211779-DD (1) (1).webp'; // Asegúrate de cambiar los nombres de las imágenes
import image2 from './uploads/1716068222367-CC (1) (1).webp'; // Asegúrate de cambiar los nombres de las imágenes
import image3 from './uploads/1716068231040-BB (2) (1).webp';
import image4 from './uploads/1716068248066-AA (1) (1).webp';
import image5 from './uploads/image5.webp';


function Home() {
  const [carouselImages, setCarouselImages] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  
  const images = [image1, image2, image3, image4]; 
  

  useEffect(() => {
    
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

 
  const lazyImages = document.querySelectorAll('.lazy');

// Configura el Intersection Observer para observar cada imagen
const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    // Verifica si la imagen está actualmente visible en el área visible del navegador
    if (entry.isIntersecting) {
      const img = entry.target;
      const src = img.getAttribute('data-src'); // Obtén el atributo data-src que contiene la URL de la imagen
      img.setAttribute('src', src); // Asigna la URL de la imagen al atributo src para cargarla
      observer.unobserve(img); // Deja de observar la imagen una vez que se haya cargado
    }
  });
});

  const settings = {
    dots: true,
    infinite: true,
    speed: 150,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="home-container">
      <div>
      {!isMobile ? (
        <>
          <div className="overlay-text">Bienvenue chez Taco Memo !</div>
          <div className="social-icons">
            <a href="https://www.facebook.com/TacoMemo/?locale=es_LA" target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="Facebook" className="social-icon" />
            </a>
            <a href="https://www.instagram.com/taco_memo/" target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} alt="Instagram" className="social-icon" />
            </a>
            <a href="https://www.google.com/search?sca_esv=52387aaaa078a480&sca_upv=1&rlz=1C1VDKB_frFR1083FR1083&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7dzCrXx3tjwfhTmCCO6zsRaROs-jfIkTeuuSDdRl9YrX_J26jbpdvuWYG279XC1Wsy7lm-xMNt8kv3TP1Ygr3pcwWmYs-3tICJuUSIVo6gUljRP4Al&si=ACC90nzZwgO0P4Qu3-A0NldYnwAKwrAmvjJXmiihL2mEqps3AwbDPuQPShkwq8EH5F2-l_4hTRBXcO1PmrOL-Q9AGj1t_R70mbzPzyD3smh6MAkZpHx-s8CY9LgiOPDHHEell6ZduXBP&q=Taco+Memo+Opiniones&hl=es-419&sa=X&ved=2ahUKEwix38iazreGAxVpR6QEHQUgErMQ_4MLegQISRAN&biw=1536&bih=695&dpr=1.25" target="_blank" rel="noopener noreferrer">
              <img src={google} alt="google opinions" className="social-icon" />
            </a>
            <a href="https://deliveroo.fr/fr/menu/lille/lille-wazemmes/taco-memo" target="_blank" rel="noopener noreferrer">
              <img src={deliverooIcon} alt="Deliveroo" className="social-icon" />
            </a>
          </div>
          <Slider className='slick-slider' {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img 
                  src={image} 
                  alt={`Slide ${index + 1}`} 
                  className="lazy" 
                  loading="lazy"
                  width="800" // Spécifiez la largeur souhaitée
                  height="auto" 
                />
              </div>
            ))}
          </Slider>
        </>
      ) : (
        <>
          <div className="overlay-text">Bienvenue chez Taco Memo !</div>
          <div className="social-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="Facebook" className="social-icon" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} alt="Instagram" className="social-icon" />
            </a>
            <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
              <img src={google} alt="TripAdvisor" className="social-icon" />
            </a>
            <a href="https://deliveroo.co.uk" target="_blank" rel="noopener noreferrer">
              <img src={deliverooIcon} alt="Deliveroo" className="social-icon" />
            </a>
          </div>
          <div className="mobile-image-container">
            <img src={image5} alt="Imagen fija" width="320px" height="750px" className="mobile-image" />
          </div>
        </>
      )}
      </div>
      <div className="content-section">

        <h1 className='h11'>Notre engagement</h1>
        <div className="cards-row">
          <div className='cardss1'>
            <div>
            <div><img src={etoile} alt="Slide 1" width='140px' height='140px' classname="lazy"/></div>
            </div>
            <div><h2>Authenticité des saveurs</h2></div>
            <div class="texto-justificado"><p>Notre équipe de cuisine, formée à Mexico, vous fait découvrir une cuisine authentique au plus proche de ce qui se fait au Mexique. Venez découvrir ces saveurs à travers nos recettes de plats et sauces « maison »</p></div>
          </div>
          <div className='cardss2'>
            <div>
               <div className='cvb'><img src={piment} alt="Slide 1" width='140px' height='140px' classname="lazy"/></div>
              </div>
            <div><h2>Fraicheur et cuisine maison</h2></div>
            <div class="texto-justificado"><p>Notre choix est de vous proposer une carte restreinte qui nous permet de tenir notre engagement à vous cuisiner des produits frais et maison. Depuis 2011 nous avons sélectionné avec exigence nos fournisseurs pour une qualité de produits irréprochable, notamment pour nos avocats, un des produits star de notre cuisine</p></div>
          </div>
          <div className='cardss3'>
             <div>
              <div><img src={heart} alt="Slide 1" width='140px' height='140px' classname="lazy"/></div>
            </div>
            <div><h2>Convivialité et service</h2></div>
            <div class="texto-justificado"><p>Chez nous on travaille avec sérieux mais également dans la bonne humeur et avec le sourire. Notre équipe dynamique et passionnée se fait donc un plaisir de vous accueillir dans notre restaurant.</p></div>
          </div>
        </div>
        <div className="cards-column">
          <div className='testim'>
            <div className='texti'><p>"Je viens de finir mon burrito... et j'ai déjà<br />  envie d'y retourner pour en reprendre un !<br />C'est délicieux, j'aime bien le lieu et l'ambiance. <br/> et je crois même que c'est bon pour la santé!"<br /></p></div>
            <div className='rat'>
              <div className='x'><p>Joseph M, avril 2024 </p></div>
              <div className='x'>
                {[1, 2, 3, 4, 5].map((star) => (
                  <FontAwesomeIcon key={star} icon={faStar} style={{ color: 'green' }} />
                ))}
              </div>
            </div>
            
          </div>
          <div className='testimdr'>
            <div className='textidr'><p>" Super cuisine, le personnel est toujours agréable, <br /> rapide et souriant. Les burritos au top, ingrédients frais! <br /> Si on pouvait on irait toutes les semaines! " </p></div>
            <div className='ratdr'>
              <div className='x y'><p>Helean O , avril 2024 </p></div>
              <div className='x'>
                {[1, 2, 3, 4, 5].map((star) => (
                  <FontAwesomeIcon key={star} icon={faStar} style={{ color: 'green' }} />
                ))}
              </div>
            </div>
            
          </div>
          <div className='testim'>
            <div className='texti'><p>" Un excellent assaisonnement me rappelle  <br /> sans aucun doute mon Mexique bien-aimé ❤️.  <br />C'est devenu mon restaurant préféré 💗💗"<br /></p></div>
            <div className='rat'>
              <div className='x'><p>Alejandra Villanueva , avril 2024 </p></div>
              <div className='x'>
                {[1, 2, 3, 4, 5].map((star) => (
                  <FontAwesomeIcon key={star} icon={faStar} style={{ color: 'green' }} />
                ))}
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Home;