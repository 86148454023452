import React, { Component, useState } from 'react';
import './Footer.css'; // Asegúrate de que la ruta al archivo CSS es correcta
import logo from '../../images/Logotacomemo.svg'; // Asegúrate de que la ruta al logo es correcta
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTripadvisor, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faMotorcycle } from '@fortawesome/free-solid-svg-icons';
// Importa los iconos de las redes sociales si los tienes como componentes o imágenes
import facebookIcon from './facebook.webp';
import instagramIcon from './instagram.webp';
import deliverooIcon from './deliveroo.webp';
import google from './g.webp';

function Footer () {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    

  return (
    <footer className="footer-container">
      {/* Repetición del layout del header */}
      <div className="header-containera">
      <div className='HL'>
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className='ss'>
        <button className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <span></span> {/* Este span será estilizado para representar las líneas de la hamburguesa */}
        </button>
        <div className={`NL ${isMenuOpen ? 'open' : ''}`}>
            <nav>
            <ul className="nav-list">
                <li className="nav-item"><a href="#a-propos">À propos</a></li>
                <li className="nav-item"><a href="#carte">Carte</a></li>
                <li className="nav-item"><a href="#actualite">Actualité</a></li>
                <li className="nav-item"><a href="#livraison">Livraison</a></li>
                <li className="nav-item"><a href="#click-and-collect">Click and Collect</a></li>
            </ul>
            </nav>
        </div>
      </div> 
    </div>

      {/* Segunda sección del footer */}
      <div className="footer-bottom">
        <div className="footer-text">©2024 TACO MEMO, tous droits réservés.</div>
        <div className="footer-socials">
          {/* Iconos de redes sociales */}
          <a href="https://www.facebook.com/TacoMemo/?locale=es_LA" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="Facebook" className="social-icon" />
          </a>
          <a href="https://www.instagram.com/taco_memo/" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" className="social-icon" />
          </a>
          <a href="https://www.google.com/search?sca_esv=52387aaaa078a480&sca_upv=1&rlz=1C1VDKB_frFR1083FR1083&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7dzCrXx3tjwfhTmCCO6zsRaROs-jfIkTeuuSDdRl9YrX_J26jbpdvuWYG279XC1Wsy7lm-xMNt8kv3TP1Ygr3pcwWmYs-3tICJuUSIVo6gUljRP4Al&si=ACC90nzZwgO0P4Qu3-A0NldYnwAKwrAmvjJXmiihL2mEqps3AwbDPuQPShkwq8EH5F2-l_4hTRBXcO1PmrOL-Q9AGj1t_R70mbzPzyD3smh6MAkZpHx-s8CY9LgiOPDHHEell6ZduXBP&q=Taco+Memo+Opiniones&hl=es-419&sa=X&ved=2ahUKEwix38iazreGAxVpR6QEHQUgErMQ_4MLegQISRAN&biw=1536&bih=695&dpr=1.25" target="_blank" rel="noopener noreferrer">
            <img src={google} alt="google opinions" className="social-icon" />
          </a>
          <a href="https://deliveroo.fr/fr/menu/lille/lille-wazemmes/taco-memo" target="_blank" rel="noopener noreferrer">
            <img src={deliverooIcon} alt="Deliveroo" className="social-icon" />
          </a>

        </div>
        <div className="footer-text">Development Web: Groupe Taer <br /> Design : <a href="https://www.instagram.com/la_anahuac/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>@la_anahuac</a></div>
      </div>
    </footer>
  );
}

export default Footer;