import React, { Component, useState, useEffect } from 'react';

function ClickModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  useEffect(() => {
    if (!isModalOpen) return;

    const script = document.createElement('script');
    script.id = 'zelty-order-iframe';
    script.type = 'text/javascript';
    script.src = 'https://order.zelty.fr/loader-iframe.js';
    script.async = true;

    window._zwg = window._zwg || {};
    window._zwg.key = 'tu_clave_aquí'; // Asegúrate de reemplazar 'tu_clave_aquí' con tu clave real de Zelty

    document.body.appendChild(script);

    return () => {
      const scriptToRemove = document.getElementById('zelty-order-iframe');
      if (scriptToRemove) {
        document.body.removeChild(scriptToRemove);
      }
      delete window._zwg;
    };
  }, [isModalOpen]);

  return (
    <div>
      <button onClick={() => setIsModalOpen(true)}>Abrir Modal de Compra</button>

      {isModalOpen && (
        <>
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1050,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }} onClick={() => setIsModalOpen(false)}>
            <div onClick={(e) => e.stopPropagation()} style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '10px',
              width: '80%',
              maxWidth: '500px',
              maxHeight: '600px',
              overflowY: 'auto',
              zIndex: 1051
            }}>
              <h2>Compra en Zelty</h2>
              <p>Integración con el sistema de Zelty como un modal.</p>
              <button onClick={() => setIsModalOpen(false)}>Cerrar</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ClickModal;