import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import './dashboard.css';

function Dashboard() {
  const [activeContent, setActiveContent] = useState('carousel'); // Inicializa como 'carousel'
  const [carouselImages, setCarouselImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  

  // Obtener las imágenes del carousel al cargar el componente
  useEffect(() => {
    fetchCarouselImages();
  }, []);

  const fetchCarouselImages = async () => {
    try {
      const response = await axios.get('http://localhost:5000/carousel-images');
      setCarouselImages(response.data);
    } catch (error) {
      console.error('Error al obtener las imágenes del carousel:', error);
    }
  };

  const handleDeleteImage = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/delete-carousel-image/${id}`);
      fetchCarouselImages(); // Actualizar la lista de imágenes después de eliminar
    } catch (error) {
      console.error('Error al eliminar la imagen del carousel:', error);
    }
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleImageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('image', selectedImage);
      await axios.post('http://localhost:5000/add-carousel-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchCarouselImages(); // Actualiser les images
      setSelectedImage(null); // Nettoyer l'état
      alert('Image uploadée avec succès !'); // Affiche une alerte en cas de succès
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      alert('Erreur lors du téléchargement de l\'image.'); // Affiche une alerte en cas d'erreur
    }
  };

  return (
    <div className="admin-panel">
      <div className="sidebar">
        <nav>
          <ul>
            <li>
              <button onClick={() => setActiveContent('carousel')}>Carousel</button>
            </li>
          </ul>
        </nav>
      </div>
      <div className="content">
        {activeContent === 'carousel' && (
          <div>
            <h2>Modifier le Carousel </h2>
            <div>
            {carouselImages.map(image => (
            <div className="carousel-images" key={image.id}>
              {/* Cambia la ruta aquí para usar la URL completa servida por el servidor */}
              <div><img src={`http://localhost:5000/uploads/${image.image_path.split('\\').pop()}`} alt={`Slide ${image.id}`} /></div>
                <div><button onClick={() => handleDeleteImage(image.id)}>Eliminer</button></div>
              </div>
            ))}
            </div>
            <div>
              <h3>Ajouter une nouvelle image</h3>
              <input className='der' type="file" onChange={handleImageChange}  />
              <button onClick={handleImageUpload}>Telecharger l'image</button>
            </div>
            <button>Actualiser le carousel</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;