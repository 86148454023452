import React, { Component, useState, useEffect } from 'react';
import Slider from 'react-slick'; // Asegúrate de importar Slider
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import './lacarte.css'; // Asegúrate de que el archivo CSS esté correctamente vinculado

import slide1 from '../Home/cov.webp';
import slide2 from '../Home/tt.webp';

import miImagenDeBorde from  '../Home/border.webp';
import Carte from './Lacarte.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPepperHot } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import ScrollToTopButton from './ScrollToTopButton'; 
import img1 from './guacamole.webp';
import qviande from './quesadillaviande.webp';
import quesadilla from './quesadilla.webp';
import guacamole from './guacamole.webp';
import soupe from './soupe.webp';
import ceviche from './ceviche.webp';
import beb from './beb.webp';
import burritoentree from './burritoentree.webp';
import nosburritos from './nosburritos.webp';
import cajun from './cajun.webp';
import vegetarien from './vegetarien.webp';
import carnitas from './carnitas.webp';
import img5 from './carnitas.webp';
import barbacoa from './barbacoa.webp';
import vegan from './vegan.webp';
import poblano from './poblano.webp';
import mole from './mole.webp';
import carlota from './carlota.webp';
import mazapan from './mazapan.webp';
import coca from './coca.webp';
import oasis from './oasis.webp';
import lipton from './lipton.webp';
import maidpomme from './maidpomme.webp';
import lime from './lime.webp';
import guava from './guava.webp';
import crapefruit from './crapefruit.webp';
import cola from './cola.webp';
import vittel from './vittel.webp';
import sanpe from './sanpe.webp';
import corona from './corona.webp';
import anosteke from './anosteke.webp';
import chouffe from './chouffe.webp';
import ClickModal from '../click/click.jsx'; 
import nutellitas from './nutellitas.webp';
import tacoss from './tacoss.webp';
import burritosingluten from './burritosingluten.webp';
import burritoformule from './burritoformule.webp';
import tacos from './tacos.webp';
import fofo from './image8.webp';
import laca from './laca.webp';





function LaCarte() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  

  // Simula el cierre de la modal
  const closeModal = () => setIsModalOpen(false);
  
  // Función para manejar el desplazamiento hacia las anclas
  useEffect(() => {
    
 
    // Configura el objeto _zwg antes de cargar el script
    window._zwg = window._zwg || {};
    window._zwg.key = 'c8f5abe51151c76'; // Tu clave real aquí
    window._zwg.stylesheet = 'https://localhost:3000/module.css';
    window._zwg.offset = 400;
   
    // Crea y agrega el script de Zelty al head del documento
    const script = document.createElement('script');
    script.src = 'https://order.zelty.fr/loader.js';
    script.async = true;
    document.head.appendChild(script); // Añade al head en lugar de body

    return () => {
      // Eliminar el script al desmontar el componente
      document.head.removeChild(script);
    };
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Cambia esto según el tamaño de la pantalla
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 768, // Por ejemplo, cambiar a 1 slide en dispositivos móviles
        settings: {
          slidesToShow: 1,
          adaptiveHeight: true,
        },
      },
      // Agrega más breakpoints según sea necesario
    ],
  };
 
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }

  };
 
  return (
    <div className="la-carte-page">
        
        <div className="slider-container">
        <Slider {...settings}>
          <div className="slide">
            <img src={slide1} alt="Slide 1" width="1280" height="820" />
            <div className="slide-title-container">
              <div className="slide-title">La carte</div>
            </div>
          </div>
          <div className="slide">
            <img src={slide2} alt="Slide 2" width="1280" height="820" />
            <div className="slide-title-container">
              <div className="slide-title">La carte</div>
            </div>
          </div>
        </Slider>
      </div>

      <div className="fixed-image-container">
        <img src={laca} alt="Burrito Entree" />
        <div className="slide-title-container">
          <div className="slide-title">La carte</div>
        </div>
      </div>

      
      <div className='backo'>
      <div className='backi'>
      {/* Barra de pestañas */}
      <div className="tabs-bar">
        <button onClick={() => scrollToSection('entrees')}>Entrées</button>
        <button onClick={() => scrollToSection('Formules')}>Formules</button>
        <button onClick={() => scrollToSection('Burritos')}>Burritos</button>
        <button onClick={() => scrollToSection('Sauces')}>Sauces</button>
        <button onClick={() => scrollToSection('Plats typiques')}>Plats typiques</button>
        <button onClick={() => scrollToSection('Desserts')}>Desserts</button>
        <button onClick={() => scrollToSection('Boissons')}>Boissons</button>
      </div>
      
      {/* Secciones de contenido */}
      <div className='listemenu'>
      <div className='section-container' id="entrees">
         <div><h1 className='tjaune'>Nos entrées</h1></div>
         <div className='ordresection'>
        <div className="sectio">
          <img src={guacamole} alt="Slide 1" width="400"  height="300" loading="lazy" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Guacamole et tortillas chips</h3></div>
            <div className="carrito-container">

            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'>   <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Notre fierté : Purée d'avocat assaisonnée avec du jus de citron vert, du sel et du pico de gallo, accompagnée de chips "totopos"</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={quesadilla} alt="Slide 1" width="400"  height="300" loading="lazy" className="card-image"/>
          <div className="card-contento">
            <div className="card-title"><h3>Quesadillas  (x2)</h3></div>
            <div className="carrito-container">
            <div className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </div>
          </div>
            <div className="card-description"><p>Un classique : Tortillas de blé garnies de fromage assaisonné avec des épices mexicaines.</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={qviande} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Quesadillas viande (x2)</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Crêpes mexicaines au fromage et à la viande</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={soupe} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Soupe aztèque</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p> Notre vedette : Soupe à base de tomate légèrement pimentée, garnie de crème fraîche, de fromage et de dés d'avocat.</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={ceviche} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Ceviche de saumon</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p> Pour voyager : Tartare de saumon mélangé avec des oignons rouges, de la coriandre fraîche, du jus d'agrumes et des tomates, accompagné de chips "totopos".</p></div>
          </div>
        </div>
        </div>
        </div>


        <div className='section-container' id="Formules">
      
        <div><h1 className='tjaune'>Formules</h1></div>
         <div className='ordresection'>
       
        <div className="sectio">
          <img src={beb} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Burrito et boisson</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Burrito & boisson</p></div>
          </div>
        </div>
        
          <div className="sectio">
          <img src={burritoformule} alt="Slide 1" width="400"  height="300"  className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Burrito, entrée & boisson</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Burrito, entrée & boisson</p></div>
          </div>
        </div>          
          <div className="sectio">
          <img src={burritoentree} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Burrito & entrée</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Burrito & entrée</p></div>
          </div>
        </div>
       
        </div>
        
        </div>
        
        <div className='section-container' id="Burritos">
        <div><h1 className='tjaune'>Burritos</h1></div>
         <div className='ordresection'>
         
        <div className="sectio">
          <img src={burritoformule} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Nos burritos</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Riz, haricots rouges, maïs, pico de gallo (tomates, coriandre fraîche, oignons, non pimenté), crème fraîche et fromage. Envie de retirer un ou plusieurs ingrédients ? Faites-le nous savoir dans les notes</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={cajun} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Poulet Cajun</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Poulet mariné avec des épices cajun, de l'ail et de l'huile d'olive, grillé à la plancha.</p></div>
          </div>
        </div>

        <div className="sectio">
          <img src={vegetarien} alt="Slide 1"  width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Végétarien</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Garniture : riz, haricots rouges, maïs, pico de gallo (tomates, citron vert, coriandre fraîche), crème fraîche et fromage</p></div>
          </div>
        </div>
 
          <div className="sectio">
          <img src={carnitas} alt="Slide 1" width="400"  height="300"  className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Porc "Carnitas"</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Porc assaisonné avec du laurier et de l'origan, puis cuit à basse température pendant 5 heures, préparé à la manière du pulled pork.

</p></div>
          </div>
        </div>
           
          <div className="sectio">
          <img src={carnitas} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Burrito Cochinita pibil</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Burrito a la viande de porc façon Cochinita pibil, aux gout d'agrumes et "achiote", accompagné des haricots rouges, riz, pico de gallo, oignons rouges style pickles et fromage.</p></div>
          </div>
        </div>
            
          <div className="sectio">
          <img src={barbacoa} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Barbacoa de Bœuf </h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Bœuf effiloché mariné dans une sauce tomate et cuit à basse température pendant 7 heures.</p></div>
          </div>
        </div>
            <div className="sectio">
          <img src={vegan} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Végan</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Garniture : riz, haricots rouges, maïs, pico de gallo (tomates, citron vert et coriandre fraiche), salade, sauce maison au choix</p></div>
          </div>
        </div>
            
            <div className="sectio">
          <img src={poblano} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Burrito Poblano</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Garniture : riz à la mexicaine, haricots rouges, poulet hallal, mais, crème, sauce « mole » au piment, épices et cacao.</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={burritosingluten} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Burrito sans gluten</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Burrito sin gluten</p></div>
          </div>
        </div>

        </div>
        
        </div>



        <div className='section-container' id="Plats typiques">
        <div><h1 className='tjaune'>Plats typiques</h1></div>
         <div className='ordresection'>
        
        <div className="sectio">
          <img src={mole} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Mole poblano</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Plat traditionnel mexicain au poulet, sauce cacao, piments et sésame, accompagné de riz rouge</p></div>
          </div>
        </div>
       
          <div className="sectio">
          <img src={tacoss} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Tacos de Cochinita Pibil </h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Emblématique du Yucatan, préparé avec du porc marine pendant 8 heures  dans une pâte d'achiote et d'agrumes, puis cuit à basse température. Garni de pickles d'oignons rouges, de coriandre fraîche et d'oignons.</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={tacoss} alt="Slide 1" width="400"  height="300"  className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Tacos de Barcacoa de boeuf </h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Emblématique du Yucatan, préparé avec du porc marine pendant 8 heures  dans une pâte d'achiote et d'agrumes, puis cuit à basse température. Garni de coriandre fraîche et d'oignons.</p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={tacos} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Tacos de Carnitas de Porc</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Emblématique du Yucatan, préparé avec du porc marine pendant 8 heures  dans une pâte d'achiote et d'agrumes, puis cuit à basse température. Garni de coriandre fraîche et d'oignons.</p></div>
          </div>
        </div>
            <div className="sectio">
          <img src={soupe} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Grande soupe aztèque</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>Délicieuse soupe maison à base de tomates, légèrement relevée, et garnie de dés d’avocats, crème fraiche et fromage. 65cl</p></div>
          </div>
        </div>
        </div>
        
        </div>

       
        
    <div className='section-container' id="Sauces">
      <div><h1 className='tjaune'>Sauces maison</h1></div>
        <div className="section1">
          <div className='part22'>
            <div className='Pi'><FontAwesomeIcon icon={faPepperHot} size="2x" color="black" /><FontAwesomeIcon icon={faPepperHot} size="2x" color="black" /><FontAwesomeIcon icon={faPepperHot} size="2x" color="black" /><FontAwesomeIcon icon={faPepperHot} size="2x" color="black" /></div>
             <h3>Salsa macha</h3>
            <p>Une sauce élaborée à partir de piments de árbol, de cacahuètes et d'huile d'olive, destinée aux amateurs des sensations fortes.</p>
          </div>
          <div className='part22'>
            <div className='Pi'><FontAwesomeIcon icon={faPepperHot} size="2x" color="red" /><FontAwesomeIcon icon={faPepperHot} size="2x" color="red" /><FontAwesomeIcon icon={faPepperHot} size="2x" color="red" /> </div>
            <h3>Salsa roja</h3>
            <p>Notre recette mexicaine spéciale avec divers piments, dont le chipotle, offrant une saveur fumée unique. Idéale pour les palais avertis.</p>
          </div>
          <div className='part22'>
            <div className='Pi'><FontAwesomeIcon icon={faPepperHot} size="2x" color="green" /> <FontAwesomeIcon icon={faPepperHot} size="2x" color="green" /></div>
            <h3>Salsa verde</h3>
            <p> Des piments verts frais, de la coriandre fraîche, des tomates vertes pour une sauce incontournable de la gastronomie mexicaine</p>
          </div>
          <div className='part22'>
            <div className='Pi'><FontAwesomeIcon icon={faPepperHot} size="2x" color="orange" /></div>
             <h3>Salsa Sesamo</h3>
            <p> Une sauce non pimentée mais délicieuse à base de tomates et de graines de sésame grillées.</p>
          </div>
        </div>
    </div>
      
      <div className='section-container' id="Desserts">
      
      <div><h1 className='tjaune'>Desserts</h1></div>
       <div className='ordresection'>
     
      <div className="sectio">
        <img src={carlota} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
        <div className="card-contento">
          <div className="card-title"><h3>Carlota de limón</h3></div>
          <div className="carrito-container">
          <div><a href="/ruta-al-carrito" className="enlace-carrito">
          <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
    </a></div>
        </div>
          <div className="card-description"><p>Variante mexicaine du tiramisu au citron vert.</p></div>
        </div>
      </div>
      
        <div className="sectio">
        <img src={mazapan} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
        <div className="card-contento">
          <div className="card-title"><h3>Mazapan de cacahuate</h3></div>
          <div className="carrito-container">
          <div><a href="/ruta-al-carrito" className="enlace-carrito">
          <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
    </a></div>
        </div>
          <div className="card-description"><p>Sablé mexicain à la cacahuète.</p></div>
        </div>
      </div>          
        <div className="sectio">
        <img src={nutellitas} alt="Slide 1" width="400"  height="300"  className="card-image" loading="lazy"/>
        <div className="card-contento">
          <div className="card-title"><h3>Nutellitas</h3></div>
          <div className="carrito-container">
          <div><a href="/ruta-al-carrito" className="enlace-carrito">
          <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
    </a></div>
        </div>
          <div className="card-description"><p>
Crêpes mexicaines au Nutella. 2 pièces.</p></div>
        </div>
      </div>
     
      </div>
      
      </div>
      <div className='section-container' id="Boissons">
      <div><h1 className='tjaune'>Boissons</h1></div>
         <div className='ordresection'>

        <div className="sectio">
          <img src={coca} alt="Slide 1"  width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Coca-Cola 33 cl</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>

        <div className="sectio">
          <img src={coca} alt="Slide 1"  width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Coca-Cola zéro sucres 33 cl</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
       
        <div className="sectio">
          <img src={oasis} alt="Slide 1" width="400"  height="300"  className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Oasis tropical 33 cl</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
        
        <div className="sectio">
          <img src={lipton} alt="Slide 1"  width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Lipton 33 cl</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
        
        <div className="sectio">
          <img src={maidpomme} alt="Slide 1" width="400"  height="300"  className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Minut Maid Pomme 33 cl</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
       
        <div className="sectio">
          <img src={lime} alt="Slide 1"  width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Jarritos citron vert 33 cl</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
       
       
        <div className="sectio">
          <img src={guava} alt="Slide 1"  width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Jarritos goyave 33 cl</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
        
        <div className="sectio">
          <img src={crapefruit} alt="Slide 1"width="400"  height="300"  className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Jarritos pamplemouse 33 cl</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={cola} alt="Slide 1"  width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Jarritos cola 33 cl</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={vittel} alt="Slide 1"  width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Vittel 50 cl</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
        <div className="sectio">
          <img src={sanpe} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>San Pellegrino 50 cl</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p></p></div>
          </div>
        </div>
       
            
            
        </div>
        
      </div>
     
     
         <div className='ordresection'>
        
        <div className="sectio">
          <img src={corona} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Corona</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>35,5 cL, 4,5°</p></div>
          </div>
        </div>
       
          <div className="sectio">
          <img src={anosteke} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Anosteke</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>33 cL, 8°</p></div>
          </div>
        </div>
          <div className="sectio">
          <img src={chouffe} alt="Slide 1" width="400"  height="300" className="card-image" loading="lazy"/>
          <div className="card-contento">
            <div className="card-title"><h3>Chouffe</h3></div>
            <div className="carrito-container">
            <div><a href="/ruta-al-carrito" className="enlace-carrito">
            <div className='XS'> <button className="zorder"><i className="fas fa-shopping-cart icono-carrito"></i>   click & collect</button> </div>
      </a></div>
          </div>
            <div className="card-description"><p>33 cL, 8°</p></div>
          </div>
        </div>
            
        </div>
        
      </div>

      </div>
      </div>
     
      <ScrollToTopButton />
      {isModalOpen && (
        <button onClick={closeModal} style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          zIndex: 6000, // Asegura que el botón esté por encima de la modal
          padding: '10px',
          fontSize: '16px',
          borderRadius: '50%',
          cursor: 'pointer',
        }}>X</button>
      )}

    </div>
  );
}

export default LaCarte;